import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  children: React.ReactNode;
  id?: string;
  className?: string;
}

const Portal = ({ children, id, className }: PortalProps) => {
  const portalRootRef = useRef(document.createElement("div"));

  useEffect(() => {
    const portalRoot = portalRootRef.current;

    if (id) {
      portalRoot.id = id;
    }

    if (className) {
      portalRoot.className = className;
    }

    document.body.appendChild(portalRoot);
    return () => {
      document.body.removeChild(portalRoot);
    };
  }, [id, className]);

  return createPortal(children, portalRootRef.current);
};

export default Portal;
