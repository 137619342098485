import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BANTER_WORD,
  BANTER_STATS,
  BANTER_USER,
  BANTER_RESET,
} from "@/app/_consts/internal";

export const fetchWordOfTheDay = createAsyncThunk(
  "banter/fetchWordOfTheDay",
  async ({ accessToken, gameId }: { accessToken: string; gameId?: string }) => {
    const response = await axios.get(
      `${BANTER_WORD}${gameId ? "?date=" + gameId : ""}`,
      {
        headers: { Authorization: accessToken },
      },
    );
    return response.data;
  },
);

export const fetchUserStats = createAsyncThunk(
  "banter/fetchUserStats",
  async (accessToken: string = "") => {
    const response = await axios.get(`${BANTER_STATS}`, {
      headers: { Authorization: accessToken || "" },
    });
    return response.data;
  },
);

export const fetchUserLastGame = createAsyncThunk(
  "banter/fetchUserLastGame",
  async (accessToken: string = "") => {
    const response = await axios.get(`${BANTER_USER}`, {
      headers: { Authorization: accessToken || "" },
    });
    return response.data ? response.data.pop() || {} : null;
  },
);

export interface UserLastGameStats {
  guesses: string[];
  id: string;
  startTime: string;
  updatedAt: string;
  win: boolean;
}

export const fetchResetUser = createAsyncThunk(
  "banter/fetchResetUser",
  async (accessToken: string) => {
    const response = await axios.put(
      `${BANTER_RESET}`,
      {},
      { headers: { Authorization: accessToken } },
    );
    return response.data;
  },
);

interface BanterGameResult {
  gameId: string;
  guess: string;
  startTime: string;
}

export const putGameResult = createAsyncThunk(
  "banter/putGameResult",
  async ({
    gameResult,
    accessToken = "",
  }: {
    gameResult: BanterGameResult;
    accessToken: string;
  }) => {
    const response = await axios.put(
      `${BANTER_USER}`,
      { ...gameResult },
      {
        headers: { Authorization: accessToken || "" },
      },
    );
    return response.data;
  },
);

export interface BanterGameStats {
  gamesPlayed: number;
  winPercentage: number;
  currentStreak: number;
  maxStreak: number;
  distribution: {
    "5": number[] | undefined;
    "6": number[] | undefined;
    "7": number[] | undefined;
  };
}

const banterSlice = createSlice({
  name: "banter",
  initialState: {
    wordOfTheDay: null,
    userLastGame: null,
    userStats: {
      gamesPlayed: 0,
      winPercentage: 0,
      currentStreak: 0,
      maxStreak: 0,
      distribution: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWordOfTheDay.fulfilled, (state, action) => {
        state.wordOfTheDay = action.payload;
      })
      .addCase(fetchUserStats.fulfilled, (state, action) => {
        state.userStats = action.payload;
      })
      .addCase(fetchUserLastGame.fulfilled, (state, action) => {
        state.userLastGame = action.payload;
      })
      .addCase(putGameResult.fulfilled, (state, action) => {
        state.userStats = action.payload.stats;
      });
  },
});

export default banterSlice.reducer;
