import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ARTICLE_BY_ID_URL,
  IMAGES_GETURL,
  READING_LIST,
} from "@/app/_consts/internal";
import { updateNestedObject } from "@/app/_utils/helper";
import { UploadImageArgs, UploadImageResponse } from "@/app/_types";

const initialState = {
  article: {
    layout: [
      {
        type: "image",
        src: "/Chapter_1_VEG_Way/SVG/welcometothemanfeisto_Manifesto_RGB.svg",
      },
      { type: "title", text: "Add Title" },
    ],
    title: "Add Title",
    huddleQuestions: [],
    id: "",
    author: "",
    deleted: false,
  },
  newComponentIndex: null,
  loading: false,
  error: "",
  isCreatingArticle: false,
  readingList: {} as any,
  chapterDepth: "",
  readingListId: "",
  newArticlePosition: "",
  readingListData: {},
  url: "",
};

export const createArticle = createAsyncThunk<any, any>(
  "createArticle/createArticle",
  async (data) => {
    const { accessToken, layout, title, huddleQuestions } = data;
    const response = await axios.post(
      `${ARTICLE_BY_ID_URL}/create`,
      {
        Layout: layout,
        Title: title,
        AuthorName: "Testing",
        HuddleQuestions: huddleQuestions,
      },
      { headers: { Authorization: accessToken } },
    );
    const createdArticle = response.data;
    return createdArticle;
  },
);

export const createAndAddArticleToReadingList = createAsyncThunk<any, any>(
  "createAndAddArticleToReadingList",
  async ({
    readingList,
    readingListData,
    chapterDepth,
    newArticleData,
    accessToken,
  }) => {
    const createArticleResponse = await axios.post(
      `${ARTICLE_BY_ID_URL}/create`,
      {
        Layout: newArticleData.layout,
        Title: newArticleData.title,
        AuthorName: "Testing",
        HuddleQuestions: newArticleData.huddleQuestions,
      },
      { headers: { Authorization: accessToken } },
    );

    const updatedReadingListList = [...readingList.list];
    updatedReadingListList.push({
      id: createArticleResponse.data.article_number,
      title: newArticleData.title,
      type: "Article",
    });

    const updatedReadingList = {
      ...readingList,
      list: updatedReadingListList,
    };

    let newReadingList;

    if (chapterDepth === "") {
      // Directly update the list in readingListData
      newReadingList = {
        ...readingListData,
        list: updatedReadingList.list,
      };
    } else {
      // Use updateNestedObject function for nested updates
      newReadingList = updateNestedObject(
        readingListData,
        chapterDepth,
        updatedReadingList,
      );
    }

    await axios.put(
      `${READING_LIST}/${newReadingList.id}/edit`,
      {
        ...newReadingList,
      },
      { headers: { Authorization: accessToken } },
    );

    return {
      message: "Article created and added to reading list successfully!",
    };
  },
);

export const createArticleAsDraft = createAsyncThunk<any, any>(
  "createAndAddArticleToReadingList",
  async ({
    readingList,
    readingListData,
    chapterDepth,
    newArticleData,
    accessToken,
  }) => {
    const createArticleResponse = await axios.post(
      `${ARTICLE_BY_ID_URL}/new/draft`,
      {
        Layout: newArticleData.layout,
        Title: newArticleData.title,
        AuthorName: "Testing",
        HuddleQuestions: newArticleData.huddleQuestions,
      },
      { headers: { Authorization: accessToken } },
    );

    const updatedReadingListList = [...readingList.list];

    updatedReadingListList.push({
      id: createArticleResponse.data.article_number,
      title: newArticleData.title,
      status: { drafted: true, published: false },
      type: "Article",
    });

    const updatedReadingList = {
      ...readingList,
      list: updatedReadingListList,
    };

    let newReadingList;

    if (chapterDepth === "") {
      newReadingList = {
        ...readingListData,
        list: updatedReadingList.list,
      };
    } else {
      newReadingList = updateNestedObject(
        readingListData,
        chapterDepth,
        updatedReadingList,
      );
    }

    await axios.put(
      `${READING_LIST}/${newReadingList.id}/edit`,
      {
        ...newReadingList,
      },
      { headers: { Authorization: accessToken } },
    );

    return {
      message: "Article created and added to reading list successfully!",
    };
  },
);

export const uploadImageForCreateArticle = createAsyncThunk<
  UploadImageResponse,
  UploadImageArgs
>(
  "createArticle/uploadImageForCreateArticle",
  async (data, { rejectWithValue }) => {
    const { accessToken, file, filename, folder, index, isNewImage } = data;
    const fileExtension = file?.name.split(".").pop();
    try {
      const response = await axios.put(
        `${IMAGES_GETURL}`,
        {
          filename: filename,
          folder: folder,
          "content-type": `image/${fileExtension}`,
        },
        { headers: { Authorization: accessToken } },
      );
      const presignedUrl = response.data;
      await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": `image/${fileExtension}`,
        },
      });
      const imageUrl = new URL(presignedUrl).pathname;
      return { success: true, imageUrl, index, type: "image", isNewImage };
    } catch (error) {
      return rejectWithValue({ success: false, error: error });
    }
  },
);

const createArticleSlice = createSlice({
  name: "createArticle",
  initialState,
  reducers: {
    setCreateArticle(state, action) {
      state.article = action.payload;
    },
    setIsCreatingArticle(state, action) {
      state.isCreatingArticle = action.payload;
    },
    setCreateLayoutArticle(state, action) {
      const { index, item } = action.payload;
      state.article.layout[index] = item;
    },
    setCreateArticleTitle(state, action) {
      state.article.title = action.payload.title;
    },
    setCreateLoading(state, action) {
      state.loading = action.payload;
    },
    setCreateError(state, action) {
      state.error = action.payload;
    },
    setNewArticlePosition(state, action) {
      state.newArticlePosition = action.payload;
    },
    insertComponentIntoCreateLayout(state, action) {
      const { index, position, item } = action.payload;
      if (position === "top") {
        state.article.layout = [
          ...state.article.layout.slice(0, index),
          item,
          ...state.article.layout.slice(index),
        ];
        state.newComponentIndex = index;
        state.newComponentIndex = index;
      } else if (position === "bottom") {
        state.article.layout = [
          ...state.article.layout.slice(0, index + 1),
          item,
          ...state.article.layout.slice(index + 1),
        ];
        state.newComponentIndex = index + 1;
        state.newComponentIndex = index + 1;
      }
    },
    deleteComponentFromCreateLayout(state, action) {
      const index = action.payload;
      state.article.layout.splice(index, 1);
    },
    setReadingList(state, action) {
      state.readingList = action.payload.readingList;
      state.chapterDepth = action.payload.chapterDepth;
      state.readingListId = action.payload.readingListId;
      state.readingListData = action.payload.readingListData;
    },
    setUrlForCreateArticle(state, action) {
      state.url = action.payload;
    },
    setHuddleQuestionsForCreate(state, action) {
      state.article.huddleQuestions = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createArticle.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createArticle.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.article = action.payload;
      })
      .addCase(createArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "";
      })
      .addCase(uploadImageForCreateArticle.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          const { imageUrl, index, type, isNewImage } = action.payload;
          if (isNewImage) {
            state.article.layout.push({
              type: type,
              src: imageUrl,
            });
          } else {
            state.article.layout[index].src = imageUrl;
          }
        }
      })
      .addCase(uploadImageForCreateArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "";
      });
  },
});

export const {
  setCreateArticle,
  setIsCreatingArticle,
  setCreateLoading,
  setCreateError,
  setCreateLayoutArticle,
  setCreateArticleTitle,
  setReadingList,
  setNewArticlePosition,
  insertComponentIntoCreateLayout,
  deleteComponentFromCreateLayout,
  setUrlForCreateArticle,
  setHuddleQuestionsForCreate,
  resetState,
} = createArticleSlice.actions;

export default createArticleSlice.reducer;
