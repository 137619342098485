import React from "react";
import {
  HomeOutlined,
  BookOutlined,
  StarOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import AnnounceIcon from "../graphics/AnnounceIcon";
import VaultIcon from "../graphics/VaultIcon";
import FeedbackIcon from "../graphics/FeedbackIcon";
import GamesIcon from "../graphics/GamesIcon";
import GamesBanterIcon from "../graphics/GamesBanterIcon";
import GamesVEGconnectIcon from "../graphics/GamesVEGconnectIcon";
import GamesPupQuizIcon from "../graphics/GamesPupQuizIcon";
import PhotosIcon from "../graphics/PhotosIcon";
import SidebarLink, { NestedLink } from "./SidebarLink";
import { cn } from "@/app/_utils/ui";

interface SidebarItem {
  label: string;
  href: string;
  icon: React.ReactNode;
  target?: string;
  dividerAfter?: boolean;
  nestedLinks?: NestedLink[];
}

const sidebarItems: SidebarItem[] = [
  {
    label: "Home",
    href: "/",
    icon: <HomeOutlined />,
  },
  {
    label: "Manifesto by Chapter",
    href: "/reading-list/manifestobychapter",
    icon: <BookOutlined />,
  },
  {
    label: "My List",
    href: "/my-list",
    icon: <StarOutlined />,
  },
  {
    label: "Games",
    href: "/games",
    icon: <GamesIcon />,
    nestedLinks: [
      {
        label: "Banter",
        href: "/games/banter",
        icon: <GamesBanterIcon />,
      },
      {
        label: "VEGconnect",
        href: "/games/vegconnect",
        icon: <GamesVEGconnectIcon />,
      },
      {
        label: "Pup Quiz",
        href: "/games/pupquiz",
        icon: <GamesPupQuizIcon />,
      },
    ],
  },
  {
    label: "Dashboard",
    href: "/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    label: "Photos",
    href: "/photos",
    icon: <PhotosIcon />,
    dividerAfter: true,
  },
  {
    label: "News",
    href: "/news",
    icon: <AnnounceIcon />,
  },
  {
    label: "Vault Articles",
    href: "/reading-list/vault",
    icon: <VaultIcon />,
  },
  {
    label: "Give Feedback",
    href: "https://forms.monday.com/forms/embed/852beeb1ae7e1f44f09b87986df16571",
    icon: <FeedbackIcon />,
    target: "_blank",
  },
];

const SidebarLinks = ({
  collapse,
  onLinkClick,
}: {
  collapse: boolean;
  onLinkClick: () => void;
}) => {
  return (
    <div className="flex flex-col items-center gap-2 overflow-hidden sm:overflow-visible hover:overflow-visible">
      {sidebarItems.map((item, index) => (
        <React.Fragment key={index}>
          <SidebarLink
            onLinkClick={onLinkClick}
            label={item.label}
            collapse={collapse}
            href={item.href}
            target={item.target}
            nestedLinks={item.nestedLinks}
          >
            {item.icon}
            <span
              className={cn(
                "transition-all",
                collapse
                  ? "opacity-0 delay-0 duration-0"
                  : "opacity-100 delay-300 duration-300",
              )}
            >
              {item.label}
            </span>
          </SidebarLink>
          {item.dividerAfter && (
            <div className="my-2 h-px w-full bg-white/20"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SidebarLinks;
