import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { updateNestedObject } from "@/app/_utils/helper";
import { READING_LIST } from "@/app/_consts/internal";
import { RootState } from "./store";

const initialState = {
  editedReadingList: { list: [] as any[], title: "", type: "", id: "" },
  status: "idle",
  error: null as string | null,
  newItemPosition: "",
  readingListId: null,
  chapterDepth: null,
};

export const editReadingList = createAsyncThunk<any, any>(
  "readingList/editReadingList",
  async (
    { readingListId, blurb, imageSrc, list, title, type, accessToken },
    thunkAPI,
  ) => {
    try {
      const response = await axios.put(
        `/readinglist/${readingListId}/edit`,
        {
          blurb,
          id: readingListId,
          "image-src": imageSrc,
          list,
          title,
          type,
        },
        { headers: { Authorization: accessToken } },
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const addExistingItemToReadingList = createAsyncThunk<any, any>(
  "addItemToReadingList",
  async ({ accessToken, readingListData, chapterDepth }, { getState }) => {
    const updatedState = getState() as RootState;
    const currentEditedReadingList =
      updatedState.editReadingList.editedReadingList;

    let newReadingList;

    if (!chapterDepth) {
      newReadingList = {
        ...readingListData,
        list: currentEditedReadingList.list,
      };
    } else {
      newReadingList = updateNestedObject(
        readingListData,
        chapterDepth,
        currentEditedReadingList,
      );
    }
    const editReadingListResponse = await axios.put(
      `${READING_LIST}/${newReadingList.id}/edit`,
      {
        ...newReadingList,
      },
      { headers: { Authorization: accessToken } },
    );

    return {
      readingList: editReadingListResponse.data.Data,
    };
  },
);

const readingListSlice = createSlice({
  name: "readingList",
  initialState,
  reducers: {
    setNewEditReadingListPosition(state, action) {
      state.newItemPosition = action.payload;
    },
    setReadingListId(state, action) {
      state.readingListId = action.payload;
    },
    setChapterDepth(state, action) {
      state.chapterDepth = action.payload;
    },
    setEditedReadingList(state, action) {
      state.editedReadingList = action.payload;
    },
    reorganizeReadingList(state, action) {
      const { position, item } = action.payload;
      const updatedList = [...state.editedReadingList.list];
      updatedList.splice(position, 0, item);
      state.editedReadingList.list = updatedList;
    },
    reorderReadingList(state, action) {
      const { oldIndex, newIndex } = action.payload;
      if (oldIndex !== newIndex) {
        const item = state.editedReadingList.list.splice(oldIndex, 1)[0];
        state.editedReadingList.list.splice(newIndex, 0, item);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editReadingList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editReadingList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.editedReadingList = action.payload;
      })
      .addCase(editReadingList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
});

export const selectReadingList = (state: any) => state.readingList;

export const {
  setNewEditReadingListPosition,
  setReadingListId,
  setChapterDepth,
  setEditedReadingList,
  reorganizeReadingList,
  reorderReadingList,
} = readingListSlice.actions;

export default readingListSlice.reducer;
