import React, { useState, useRef } from "react";
import { useRouter } from "next/navigation";
import { SearchOutlined } from "@ant-design/icons";
import { Button } from "../buttons/button";
import ArticleSearch from "../search/ArticleSearch";
import Portal from "../layout/Portal";
import { cn } from "@/app/_utils/ui";

import { useOnClickOutside } from "usehooks-ts";
import FadeIn from "../animation/FadeIn";

const ArticleSearchDropdown = () => {
  const router = useRouter();
  const [isSearching, setIsSearching] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    setIsSearching(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className="min-h-[40px]">
      {isSearching ? (
        <Portal
          id="article-search"
          className="fixed sm:block top-3 left-0 sm:right-10 sm:left-auto z-50 w-full sm:w-[352px]"
        >
          <div
            className={cn(
              "sm:w-[368px] px-3 sm:px-0 relative",
              isSearching ? "w-[100vw] sm:w-[368px]" : "w-[75vw]",
            )}
            ref={ref}
          >
            <FadeIn>
              <ArticleSearch
                onSelectRandom={(articleId: string) => {
                  router.push(
                    `/reading-list/manifestobychapter/article/${articleId}`,
                  );
                  setIsSearching(false);
                }}
                onSelectArticle={(article) => {
                  router.push(
                    `/reading-list/manifestobychapter/article/${
                      typeof article === "string" || typeof article === "number"
                        ? article
                        : article.articleId
                    }`,
                  );
                  setIsSearching(false);
                }}
                onClear={(input) => {
                  if (input === "") setIsSearching(false);
                }}
              />
            </FadeIn>
          </div>
        </Portal>
      ) : (
        <FadeIn>
          <Button
            variant="unstyled"
            className="opacity-100 hover:opacity-80 relative -top-px"
            onClick={() => setIsSearching(true)}
          >
            <span className="text-base pr-3 hidden sm:inline-block">
              search the manifesto
            </span>
            <SearchOutlined style={{ fontSize: "22px" }} />
          </Button>
        </FadeIn>
      )}
    </div>
  );
};

export default ArticleSearchDropdown;
