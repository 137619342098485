import TopBarContent from "./TopBarContent";

const TopBar = () => {
  return (
    <div className="fixed top-0 left-0 right-0 md:static w-screen z-30 flex items-center justify-between xl:justify-end bg-white text-dark py-2 px-4 md:px-10">
      <TopBarContent />
    </div>
  );
};

export default TopBar;
