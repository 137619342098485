import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Article } from "@/app/_types";
import {
  ARTICLE_BY_ID_URL,
  USER_FAVORITE_ARTICLES,
} from "@/app/_consts/internal";

const initialState = {
  isLoading: true,
  articles: [] as Article[],
};

export const fetchUserFavoriteArticles = createAsyncThunk<any, string>(
  "userFavoriteArticles/fetchUserFavoriteArticles",
  async (accessToken) => {
    const response = await axios.get(USER_FAVORITE_ARTICLES, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

export const saveUserFavoriteArticle = createAsyncThunk<any, any>(
  "userFavoriteArticles/saveUserFavoriteArticle",
  async (data) => {
    const { accessToken, articleId } = data;
    const response = await axios.put(
      `${ARTICLE_BY_ID_URL}/${articleId}/favorites`,
      "",
      { headers: { Authorization: accessToken } },
    );
    const newFavoriteArticles = response.data;
    return newFavoriteArticles;
  },
);

const userFavoriteArticlesSlice = createSlice({
  name: "userFavoriteArticles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserFavoriteArticles.pending, (state) => {
        state.isLoading = true;
        state.articles = [];
      })
      .addCase(fetchUserFavoriteArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.articles = action.payload.favorite_articles;
      })
      .addCase(fetchUserFavoriteArticles.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(saveUserFavoriteArticle.fulfilled, (state, action) => {
        state.articles = action.payload.favorite_articles;
      });
  },
});

export default userFavoriteArticlesSlice.reducer;
