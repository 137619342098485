"use client";
import { DownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "@/app/_utils/ui";

export interface NestedLink {
  label: string;
  href: string;
  icon: React.ReactNode;
}

const SidebarLink = ({
  children,
  href,
  collapse,
  label,
  target,
  onLinkClick,
  nestedLinks,
}: {
  children: React.ReactNode;
  href: string;
  collapse: boolean;
  label: string;
  target?: string;
  onLinkClick: () => void;
  nestedLinks?: NestedLink[];
}) => {
  const [expanded, setExpanded] = useState(false);
  const pathname = usePathname();
  const active = pathname === href;
  const hasNestedLinks = nestedLinks && nestedLinks.length > 0;

  useEffect(() => {
    if (collapse) {
      setExpanded(false);
    }
  }, [collapse]);

  return (
    <>
      <Link
        className={cn(
          "group relative pl-6 h-[46px] w-[231px] hover:bg-neutral-700 ease-in-out transition-all duration-500 flex justify-start gap-3 items-center rounded-full",
          active && "bg-neutral-600",
          collapse && "w-[46px] pl-[15px]",
        )}
        href={href}
        target={target}
        aria-label={label}
        onClick={onLinkClick}
      >
        {children}
        {hasNestedLinks && (
          <>
            <button
              className={cn(
                "w-full flex justify-end text-right pr-4 relative -top-0.5 transition-all duration-150 ease-in-out delay-150",
                collapse ? "opacity-0 pointer-events-none" : "opacity-100",
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            >
              <DownOutlined
                className={cn(
                  "transition-all duration-300 ease-in-out",
                  expanded && "rotate-180",
                )}
              />
            </button>
            {collapse && (
              <div className="absolute hidden xl:flex flex-col gap-2 pointer-events-none group-hover:pointer-events-auto opacity-0 group-hover:opacity-100 -top-4 left-[98%] bg-dark transition-all duration-300 ease-in-out py-4 px-3 rounded-sm">
                {nestedLinks?.map((link, index) => {
                  const nestedActive = pathname === link.href;
                  return (
                    <Link
                      href={link.href}
                      key={index}
                      className={cn(
                        "pl-10 h-[46px] w-[215px] hover:bg-neutral-700 ease-in-out transition-all duration-500 flex justify-start gap-3 items-center rounded-full",
                        nestedActive && "bg-neutral-600",
                      )}
                    >
                      {link.icon}
                      <span>{link.label}</span>
                    </Link>
                  );
                })}
              </div>
            )}
          </>
        )}
      </Link>
      {nestedLinks && (
        <div
          className="flex flex-col gap-2 w-[231px] pl-6 transition-all duration-300 ease-in-out overflow-hidden"
          style={{
            height:
              expanded && nestedLinks ? nestedLinks.length * 46 + "px" : 0,
          }}
        >
          {nestedLinks?.map((link, index) => {
            const nestedActive = pathname === link.href;
            return (
              <Link
                href={link.href}
                key={index}
                className={cn(
                  "pl-6 h-[46px] w-full hover:bg-neutral-700 ease-in-out transition-all duration-500 flex justify-start gap-3 items-center rounded-full",
                  nestedActive && "bg-neutral-600",
                )}
                onClick={onLinkClick}
              >
                {link.icon}
                <span
                  className={cn(
                    "opacity-100",
                    collapse ? "opacity-0 pointer-events-none" : "opacity-100",
                  )}
                >
                  {link.label}
                </span>
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SidebarLink;
