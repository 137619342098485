"use client";
import React, { Suspense, useState } from "react";
import TopBar from "../nav/TopBar";
import Sidebar from "../nav/Sidebar";
import { cn } from "@/app/_utils/ui";

export const Main = ({
  children,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <TopBar />
      <Sidebar onCollapse={(collapse) => setIsCollapsed(collapse)} />
      <Suspense fallback={null}>
        <main
          className={cn(
            "flex-1 pl-0 xl:pl-64 overflow-x-hidden overflow-y-auto pt-12 mt-2 pb-16 sm:pb-0 md:py-0 mt:mt-0 transition-all ease-in-out duration-500 h-[100dvh]",
            isCollapsed && "xl:pl-16",
          )}
        >
          {children}
        </main>
      </Suspense>
    </>
  );
};
