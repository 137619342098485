"use client";
import React, { useState } from "react";
import Link from "next/link";
import HuddleLauncher from "./HuddleLauncher";
import { cn } from "@/app/_utils/ui";
import { usePathname } from "next/navigation";
import { HomeOutlined } from "@ant-design/icons";
import GamesIcon from "../graphics/GamesIcon";
import { useNewHuddleFlow } from "../../hooks/useNewHuddleFlow";
import LaunchHuddle from "../huddle/LaunchHuddle";

const MobileFooter = () => {
  const [open, setOpen] = useState(false);

  const isNewHuddleFlow = useNewHuddleFlow();
  const pathname = usePathname();
  if (pathname.includes("games/")) {
    return null;
  }

  return (
    <div
      className="sticky sm:hidden top-[100dvh] left-0 right-0 z-30 md:static w-screen items-center justify-center pointer-events-none"
      style={{ willChange: "transform" }}
    >
      <div
        className={cn(
          "absolute left-0 bg-gradient-to-t to-transparent w-full transition-all duration-300 ease-in-out",
          open
            ? "from-black/20 h-32 -top-[224px]"
            : "from-black/10 h-2 -top-[104px]",
        )}
      ></div>
      <div className={cn("relative bg-white h-[96px] -top-[96px]")}>
        <div className="w-full max-w-xl mx-auto flex justify-center items-end px-8">
          <Link
            href="/"
            className="flex flex-col items-center justify-center gap-3 p-5 pointer-events-auto"
          >
            <HomeOutlined
              className={cn(
                "scale-150",
                pathname === "/" ? "text-teal-500" : "text-neutral-500",
              )}
            />
            <div
              className={cn(
                "text-xs",
                pathname === "/" ? "text-teal-700" : "text-neutral-700",
              )}
            >
              Home
            </div>
          </Link>
          <div className="relative -top-1 px-10">
            {isNewHuddleFlow ? (
              <div className="w-full flex justify-center">
                <LaunchHuddle isCircleButton={true} />
              </div>
            ) : (
              <HuddleLauncher onToggle={(toggle) => setOpen(toggle)} />
            )}
          </div>
          <Link
            href="/games"
            className={cn(
              "flex flex-col items-center justify-center gap-3 p-5 pointer-events-auto",
              pathname.includes("games") ? "text-teal-500" : "text-neutral-500",
            )}
          >
            <div className="scale-150">
              <GamesIcon width="16" height="16" fill="currentColor" />
            </div>
            <div
              className={cn(
                "text-xs",
                pathname.includes("games")
                  ? "text-teal-700"
                  : "text-neutral-700",
              )}
            >
              Games
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
