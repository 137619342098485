import React from "react";

const AnnounceIcon = ({ width = "20", height = "21", fill = "none" }) => (
  <span role="img">
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.61903 11.0715C7.61903 10.8443 7.45903 10.6491 7.23618 10.6043L4.72189 10.1015C4.56189 10.0696 4.39665 10.1219 4.28427 10.24C4.17141 10.3581 4.12713 10.5258 4.16665 10.6839L5.81713 17.2996C5.86998 17.5119 6.06094 17.6605 6.27951 17.6605H8.18427C8.35046 17.6605 8.50427 17.5739 8.59094 17.4319C8.67713 17.29 8.68332 17.1134 8.60713 16.9658C8.02379 15.8377 7.52808 14.6715 7.61808 13.3905C7.61856 13.3796 7.61903 13.3681 7.61903 13.3572V11.0715ZM6.66665 11.4619V13.3415C6.58713 14.5319 6.92951 15.6415 7.42046 16.7081H6.65141L5.27284 11.1829L6.66665 11.4619Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.76192 6.69055C4.76192 6.42769 4.54859 6.21436 4.28573 6.21436H1.90478C1.64192 6.21436 1.42859 6.42769 1.42859 6.69055V10.0239C1.42859 10.2867 1.64192 10.5001 1.90478 10.5001H4.28573C4.54859 10.5001 4.76192 10.2867 4.76192 10.0239V6.69055ZM3.80954 7.16674V9.54769H2.38097V7.16674H3.80954Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2858 9.54744H14.762C15.0248 9.54744 15.2381 9.33411 15.2381 9.07125V7.16649C15.2381 6.90363 15.0248 6.6903 14.762 6.6903H14.2858V3.83316C14.2858 3.68649 14.2181 3.54792 14.1024 3.45792C13.9872 3.36744 13.8362 3.33554 13.6939 3.37125L4.17005 5.7522C3.95814 5.80506 3.80957 5.99554 3.80957 6.21411V10.4998C3.80957 10.727 3.96957 10.9222 4.19243 10.967L13.7162 12.8717C13.8562 12.8993 14.001 12.8632 14.1115 12.7727C14.222 12.6822 14.2858 12.5474 14.2858 12.4046V9.54744ZM7.61909 5.87173L13.3334 4.44316V11.8236L7.61909 10.6808V5.87173ZM6.66671 6.10982V10.4903L4.76195 10.1093V6.58601L6.66671 6.10982Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3885 5.23755L18.2457 4.28517C18.4952 4.20183 18.63 3.93183 18.5471 3.68279C18.4638 3.43326 18.1938 3.2985 17.9447 3.38136L15.0876 4.33374C14.838 4.41707 14.7033 4.68707 14.7861 4.93612C14.8695 5.18564 15.1395 5.3204 15.3885 5.23755Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1904 8.59545H18.0952C18.358 8.59545 18.5714 8.38211 18.5714 8.11926C18.5714 7.8564 18.358 7.64307 18.0952 7.64307H16.1904C15.9276 7.64307 15.7142 7.8564 15.7142 8.11926C15.7142 8.38211 15.9276 8.59545 16.1904 8.59545Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0252 12.3545L17.8824 13.783C18.1171 13.9007 18.4038 13.8054 18.5209 13.5702C18.6386 13.3354 18.5433 13.0488 18.3081 12.9316L15.4509 11.503C15.2162 11.3854 14.9295 11.4807 14.8124 11.7159C14.6947 11.9507 14.79 12.2373 15.0252 12.3545Z"
          fill="white"
        />
      </g>
    </svg>
  </span>
);

export default AnnounceIcon;
