import React from "react";

const PhotosIcon = ({ width = "20", height = "21", fill = "white" }) => (
  <span>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.28346 22.4775C5.57538 22.6099 4.89404 22.1432 4.76164 21.4352L2.02222 6.78369C1.95865 6.44365 2.03275 6.09229 2.22824 5.8069C2.42372 5.5215 2.72457 5.32545 3.06461 5.26187L17.7161 2.52245C18.4242 2.39006 19.1055 2.85675 19.2379 3.56483L21.9773 18.2163C22.0409 18.5563 21.9668 18.9077 21.7713 19.1931C21.5758 19.4785 21.275 19.6746 20.9349 19.7381L6.28346 22.4775ZM3.30432 6.54397L6.04374 21.1954L20.6952 18.456L17.9558 3.80455L3.30432 6.54397ZM16.5773 5.35983L18.3976 15.1511L16.2528 15.5522L16.0471 14.4088C16.0386 14.3618 16.0288 14.3152 16.018 14.2692C15.7842 13.2792 15.0212 12.516 14.0836 12.2475C13.8021 12.1669 13.505 12.1309 13.2017 12.1466L12.3871 12.291C12.6771 12.0413 12.9102 11.7274 13.0643 11.3713C13.0962 11.2976 13.1247 11.2221 13.1496 11.1449C13.1816 11.0459 13.2077 10.9442 13.2275 10.8403C13.257 10.6852 13.2725 10.5252 13.2725 10.3615C13.2725 8.95594 12.133 7.8165 10.7275 7.8165C10.6388 7.8165 10.5512 7.82103 10.4649 7.82987C10.3236 7.84436 10.1856 7.87042 10.0521 7.90708C8.97431 8.20298 8.18246 9.18977 8.18246 10.3615C8.18246 10.8399 8.31445 11.2875 8.544 11.6698C8.59457 11.754 8.64986 11.835 8.70952 11.9125C8.76122 11.9797 8.8162 12.0442 8.87422 12.1058C9.13779 12.3857 9.46409 12.606 9.83065 12.744L9.01299 12.8889C8.72397 12.9787 8.45826 13.1135 8.22214 13.2837C8.00381 13.4411 7.81079 13.6289 7.64805 13.8397C7.19037 14.4326 6.9722 15.2073 7.10424 15.9995L7.32411 17.222L6.96277 17.2896L6.96231 17.2871L5.14239 7.49835L16.5773 5.35983ZM6.66289 8.54092L6.90954 9.86755C6.97748 9.33715 7.15338 8.8405 7.41448 8.40036L6.66289 8.54092ZM7.34236 12.1955L7.36147 12.2983C7.37041 12.2915 7.37938 12.2847 7.38838 12.2779C7.37272 12.2506 7.35737 12.2232 7.34236 12.1955ZM13.1072 7.33572C14.0022 8.04056 14.5768 9.13393 14.5768 10.3615C14.5768 10.5848 14.5577 10.8037 14.5211 11.0167C15.3358 11.2681 16.0538 11.7728 16.5686 12.4489L15.5336 6.88195L13.1072 7.33572Z"
        fill="white"
      />
    </svg>
  </span>
);

export default PhotosIcon;
