import React from "react";

const GamesPupQuizIcon = ({ width = "20", height = "21", fill = "white" }) => (
  <span>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56322 16.112L7.60475 16.0305L7.52731 15.9819C5.46544 14.6871 4.1001 12.4347 4.1001 9.8714C4.1001 5.85819 7.44906 2.59998 11.5863 2.59998C15.7235 2.59998 19.0725 5.85819 19.0725 9.8714C19.0725 12.4347 17.7074 14.687 15.6453 15.9819L15.5679 16.0305L15.6094 16.112C15.9061 16.694 16.0733 17.3498 16.0733 18.0428C16.0733 20.4465 14.0672 22.4 11.5863 22.4C9.10548 22.4 7.09931 20.4465 7.09931 18.0428C7.09931 17.35 7.26646 16.6941 7.56322 16.112ZM14.149 16.1515C14.0608 16.0387 13.965 15.9319 13.8622 15.8318V15.4964C14.1306 15.3936 14.3899 15.2733 14.6386 15.1368C14.7251 15.0893 14.8104 15.0398 14.8943 14.9884C16.6404 13.9194 17.8023 12.0279 17.8023 9.8714C17.8023 6.53134 15.0166 3.82855 11.5863 3.82855C8.15602 3.82855 5.37029 6.53134 5.37029 9.8714C5.37029 12.3857 6.94937 14.5393 9.19281 15.45V15.9526C8.68127 16.5062 8.3695 17.239 8.3695 18.0428C8.3695 19.7734 9.81245 21.1714 11.5863 21.1714C13.3602 21.1714 14.8031 19.7734 14.8031 18.0428C14.8031 17.5533 14.6875 17.0899 14.4813 16.6772C14.3882 16.4908 14.2766 16.3149 14.149 16.1515Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2749 16.8113C10.2749 16.0981 10.8731 15.4999 11.5862 15.4999C12.2994 15.4999 12.8976 16.0981 12.8976 16.8113C12.8976 17.5244 12.2994 18.1226 11.5862 18.1226C10.8731 18.1226 10.2749 17.5244 10.2749 16.8113ZM11.5862 16.4753C11.3891 16.4753 11.2503 16.6141 11.2503 16.8113C11.2503 17.0084 11.3891 17.1472 11.5862 17.1472C11.7834 17.1472 11.9222 17.0084 11.9222 16.8113C11.9222 16.6141 11.7834 16.4753 11.5862 16.4753Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.175 16.8113C10.175 16.0429 10.818 15.4 11.5864 15.4C12.3548 15.4 12.9977 16.0429 12.9977 16.8113C12.9977 17.5797 12.3548 18.2226 11.5864 18.2226C10.818 18.2226 10.175 17.5797 10.175 16.8113ZM11.4152 16.6401C11.3762 16.679 11.3504 16.7368 11.3504 16.8113C11.3504 16.8858 11.3762 16.9436 11.4152 16.9825C11.4541 17.0214 11.5119 17.0473 11.5864 17.0473C11.6609 17.0473 11.7186 17.0214 11.7576 16.9825C11.7965 16.9436 11.8223 16.8858 11.8223 16.8113C11.8223 16.7368 11.7965 16.679 11.7576 16.6401C11.7186 16.6011 11.6609 16.5753 11.5864 16.5753C11.5119 16.5753 11.4541 16.6011 11.4152 16.6401ZM11.5864 15.5C10.8732 15.5 10.275 16.0981 10.275 16.8113C10.275 17.5245 10.8732 18.1226 11.5864 18.1226C12.2996 18.1226 12.8977 17.5245 12.8977 16.8113C12.8977 16.0981 12.2996 15.5 11.5864 15.5ZM11.2504 16.8113C11.2504 16.6141 11.3892 16.4753 11.5864 16.4753C11.7835 16.4753 11.9223 16.6141 11.9223 16.8113C11.9223 17.0085 11.7835 17.1473 11.5864 17.1473C11.3892 17.1473 11.2504 17.0085 11.2504 16.8113Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22681 9.55609C8.22681 7.82429 9.73065 6.49994 11.5431 6.49994C13.3555 6.49994 14.8593 7.82429 14.8593 9.55609C14.8593 11.1125 13.6126 12.3333 12.0849 12.5705V13.9778H11.0012V11.5285H11.5431C12.8142 11.5285 13.7756 10.5935 13.7756 9.55609C13.7756 8.51351 12.8518 7.58368 11.5431 7.58368C10.2343 7.58368 9.31055 8.51351 9.31055 9.55609H8.22681Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17676 9.55611C8.17676 7.7923 9.7076 6.44995 11.543 6.44995C13.3784 6.44995 14.9093 7.7923 14.9093 9.55611C14.9093 11.127 13.6631 12.357 12.1349 12.613V14.0278H10.9511V11.4785H11.543C12.7911 11.4785 13.7255 10.5615 13.7255 9.55611C13.7255 8.54501 12.8282 7.63369 11.543 7.63369C10.2578 7.63369 9.3605 8.54501 9.3605 9.55611V9.60611H8.17676V9.55611ZM8.27718 9.50611H9.26119C9.29014 8.45279 10.2314 7.53369 11.543 7.53369C12.8754 7.53369 13.8255 8.48204 13.8255 9.55611C13.8255 10.6254 12.8373 11.5785 11.543 11.5785H11.0511V13.9278H12.0349V12.5276L12.0772 12.5211C13.5851 12.287 14.8093 11.0835 14.8093 9.55611C14.8093 7.85631 13.3324 6.54995 11.543 6.54995C9.7712 6.54995 8.30582 7.83076 8.27718 9.50611Z"
        fill={fill}
      />
      <rect x="11" y="16.5" width="1" height="1" fill={fill} />
    </svg>
  </span>
);

export default GamesPupQuizIcon;
