import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openAudioPlayer: false,
  audioSrc: null,
};

const audioPlayerSlice = createSlice({
  name: "audioPlayer",
  initialState,
  reducers: {
    openAudio: (state, action) => {
      state.openAudioPlayer = true;
      state.audioSrc = action.payload.audioSrc;
    },
    closeAudio: (state) => {
      state.openAudioPlayer = false;
      state.audioSrc = null;
    },
  },
});

export const { openAudio, closeAudio } = audioPlayerSlice.actions;
export default audioPlayerSlice.reducer;
