import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { NEWS } from "@/app/_consts/internal";

const initialState = {
  isLoading: true,
  newsArray: [] as any[],
  latestNews: {
    title: "",
    layout: [],
    id: "",
    author: "",
    timestamp: "",
  },
  editedNews: {
    title: "",
    layout: [] as any[],
    id: "",
    author: "",
    timestamp: "",
  },
  isEditingNews: false,
  newComponentIndex: null,
};

export const fetchNews = createAsyncThunk<any, string>(
  "news/fetchNews",
  async (accessToken) => {
    const response = await axios.get(NEWS, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

export const updateNews = createAsyncThunk<any, any>(
  "news/updateNews",
  async (data) => {
    const { accessToken, Title, Layout, newsId } = data;
    const response = await axios.put(
      `${NEWS}/${newsId}/edit`,
      { Title, Layout },
      { headers: { Authorization: accessToken } },
    );
    const updatedNews = response.data;
    return { updatedNews };
  },
);

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setEditNews(state, action) {
      state.editedNews = action.payload;
    },
    setIsEditingNews(state, action) {
      state.isEditingNews = action.payload;
    },
    setLayoutNews(state, action) {
      const { index, item } = action.payload;
      state.editedNews.layout[index] = item;
    },
    insertComponentIntoNewsLayout(state, action) {
      const { index, position, item } = action.payload;
      if (position === "top") {
        state.editedNews.layout = [
          ...state.editedNews.layout.slice(0, index),
          item,
          ...state.editedNews.layout.slice(index),
        ];
      } else if (position === "bottom") {
        state.editedNews.layout = [
          ...state.editedNews.layout.slice(0, index + 1),
          item,
          ...state.editedNews.layout.slice(index + 1),
        ];
      }
    },
    deleteComponentFromNewsLayout(state, action) {
      const index = action.payload;
      state.editedNews.layout.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.isLoading = true;
        state.newsArray = [];
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newsArray = action.payload;

        const latestNews = action.payload.reduce((prev: any, current: any) => {
          if (new Date(current.timestamp) > new Date(prev.timestamp)) {
            return current;
          }
          return prev;
        });

        state.latestNews = {
          author: latestNews.author,
          title: latestNews.title,
          layout: latestNews.layout,
          id: latestNews.id,
          timestamp: latestNews.timestamp,
        };
      })
      .addCase(fetchNews.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        const updatedNews = action.payload.updatedNews;
        state.newsArray = state.newsArray.map((news) => {
          if (news.id === updatedNews.id) {
            return updatedNews;
          }
          return news;
        });

        state.latestNews = {
          author: updatedNews.AuthorName,
          title: updatedNews.Title,
          layout: updatedNews.Layout,
          id: state.latestNews.id,
          timestamp: updatedNews.UpdatedAt,
        };

        state.editedNews = {
          author: updatedNews.AuthorName,
          title: updatedNews.Title,
          layout: updatedNews.Layout,
          id: state.latestNews.id,
          timestamp: updatedNews.UpdatedAt,
        };
      });
  },
});

export const {
  setEditNews,
  setIsEditingNews,
  setLayoutNews,
  insertComponentIntoNewsLayout,
  deleteComponentFromNewsLayout,
} = newsSlice.actions;

export default newsSlice.reducer;
