import { configureStore } from "@reduxjs/toolkit";
import analyticsReducer from "./analyticsSlice";
import articlesReducer from "./articlesSlice";
import readingListsReducer from "./readingListsSlice";
import createArticleReducer from "./createArticleSlice";
import dailyArticleReducer from "./dailyArticleSlice";
import userReadArticlesReducer from "./userReadArticlesSlice";
import userHuddlesReducer from "./userHuddlesSlice";
import photoGalleryReducer from "./photoGallerySlice";
import weeklyStatsReducer from "./weeklyStatsSlice";
import loginReducer from "./loginSlice";
import userFavoriteArticles from "./userFavoriteArticlesSlice";
import editArticleReducer from "./editArticleSlice";
import editReadingListReducer from "./editReadingListSlice";
import locationDashboardReducer from "./locationDashboardSlice";
import userStreakStatsReducer from "./userStreakStatsSlice";
import vegWideStatsReducer from "./vegWideStatsSlice";
import newsReducer from "./newsSlice";
import huddleGroupReducer from "./huddleSlice";
import banterReducer from "./banterSlice";
import audioPlayerReducer from "./audioPlayerSlice";

const store = configureStore({
  reducer: {
    analytics: analyticsReducer,
    articles: articlesReducer,
    readingLists: readingListsReducer,
    dailyArticle: dailyArticleReducer,
    userReadArticles: userReadArticlesReducer,
    userHuddles: userHuddlesReducer,
    photoGallery: photoGalleryReducer,
    weeklyStats: weeklyStatsReducer,
    userStreakStats: userStreakStatsReducer,
    vegStats: vegWideStatsReducer,
    login: loginReducer,
    userFavoriteArticles: userFavoriteArticles,
    editArticle: editArticleReducer,
    createArticle: createArticleReducer,
    news: newsReducer,
    editReadingList: editReadingListReducer,
    locationDashboard: locationDashboardReducer,
    huddleGroups: huddleGroupReducer,
    banter: banterReducer,
    audioPlayer: audioPlayerReducer,
  },
});

// Define RootState based on the store's reducers
export type RootState = ReturnType<typeof store.getState>;

// Export the AppDispatch type
export type AppDispatch = typeof store.dispatch;

export default store;
