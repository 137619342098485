"use client";
import React, { useState } from "react";
import { Button } from "../ui/buttons/button";
import { TOKEN_RESPONSE_CONFIG } from "../reducers/loginSlice";

const CloudfrontLogin = () => {
  const [error, setError] = useState("");

  const isCloudfrontURL =
    window.location.href.includes("cloudfront") ||
    window.location.href.includes("https://localhost:19006/");

  const setTokenFromClipboard = async () => {
    try {
      const token = await navigator.clipboard.readText();
      if (token && isValidTokenFormat(token)) {
        localStorage.setItem(TOKEN_RESPONSE_CONFIG, token);
        window.location.reload();
      } else {
        setError("Invalid or empty token in clipboard.");
      }
    } catch (err) {
      setError("Failed to read from clipboard.");
    }
  };

  const isValidTokenFormat = (token: string) => {
    try {
      const parsedToken = JSON.parse(token);
      return Object.prototype.hasOwnProperty.call(parsedToken, "id_token");
    } catch (e) {
      return false;
    }
  };

  if (!isCloudfrontURL) {
    return null;
  }

  return (
    <>
      <Button variant="ghost" onClick={setTokenFromClipboard}>
        Login with Dev Token
      </Button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
};

export default CloudfrontLogin;
