import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ALL_ARTICLES, USER_SHARE } from "@/app/_consts/internal";
import { updateArticle } from "./editArticleSlice";

interface Articles {
  [key: string]: any;
}

interface ArticlesState {
  isLoading: boolean;
  articles: Articles;
}

const initialState: ArticlesState = {
  isLoading: true,
  articles: {},
};

export const fetchArticles = createAsyncThunk<any, string>(
  "articles/fetchArticles",
  async (accessToken) => {
    console.log("fetchArticles");
    const response = await axios.get(ALL_ARTICLES, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

interface ShareArticleParams {
  accessToken: string;
  articleId?: string;
  readingListId?: string;
  sublist: string[];
}

export const shareArticle = createAsyncThunk<number, ShareArticleParams>(
  "articles/shareArticle",
  async (data) => {
    const { accessToken, articleId, readingListId, sublist } = data;
    const response = await axios.post(
      `${USER_SHARE}/`,
      { articleId, readingListId, sublist },
      { headers: { Authorization: accessToken } },
    );

    return response.status;
  },
);

const articlesSlice = createSlice({
  name: "readinglists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticles.pending, (state) => {
        state.articles = {};
        state.isLoading = true;
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        const newArticles: Articles = {};
        action.payload.forEach((article: any) => {
          newArticles[article.id] = article;
        });
        state.articles = newArticles;
        state.isLoading = false;
      })
      .addCase(fetchArticles.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateArticle.fulfilled, (state, action) => {
        const { articleId, updatedArticle } = action.payload;
        state.articles[articleId].layout = updatedArticle.Layout;
        state.articles[articleId].huddleQuestions =
          updatedArticle.HuddleQuestions;
      });
  },
});

export default articlesSlice.reducer;
