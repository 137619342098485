import React from "react";

const GamesVEGconnectIcon = ({
  width = "20",
  height = "21",
  fill = "white",
}) => (
  <span>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6281 14.6329C14.6309 14.3196 14.5237 14.0318 14.3431 13.7609C14.2528 13.6254 12.8615 11.554 12.0176 11.554C11.1738 11.554 9.78252 13.6254 9.69221 13.7609C9.51159 14.029 9.40153 14.3196 9.40435 14.6329C9.40435 14.6442 9.40435 14.6555 9.40435 14.6668C9.40435 15.2764 9.89822 15.773 10.5106 15.773C11.0948 15.773 11.7665 14.9772 11.9979 14.9772C12.2293 14.9772 12.9405 15.773 13.5218 15.773C14.1314 15.773 14.6281 15.2764 14.6281 14.6668C14.6281 14.6527 14.6281 14.6442 14.6281 14.6329Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5924 11.0966C13.9141 10.8116 14.0439 10.4419 14.058 10.0271C14.0552 9.96779 14.0552 9.91699 14.0495 9.86337C14.027 9.67146 13.9705 9.49084 13.852 9.33281C13.6065 8.99697 13.1775 8.95746 12.8304 9.23403C12.3901 9.5868 12.2067 10.298 12.424 10.8229C12.6385 11.3422 13.1747 11.4692 13.5924 11.0966Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5085 11.5707C15.4774 11.3167 15.373 11.1078 15.1303 10.995C14.9299 10.9046 14.7296 10.9329 14.5377 11.0288C14.1172 11.2377 13.8575 11.7485 13.9253 12.2226C13.9958 12.6995 14.4304 12.9366 14.865 12.7249C15.2575 12.5326 15.4489 12.2014 15.5053 11.791C15.5151 11.7198 15.5143 11.6475 15.5089 11.5758C15.5087 11.5741 15.5086 11.5724 15.5085 11.5707Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4404 11.0995C10.1215 10.8173 9.98883 10.4476 9.97754 10.0327C9.98036 9.97348 9.98036 9.92269 9.98883 9.87189C10.0114 9.67998 10.0678 9.49936 10.1836 9.34415C10.4291 9.01114 10.8552 8.9688 11.2023 9.24537C11.6426 9.59814 11.8232 10.3065 11.6059 10.8258C11.3914 11.3422 10.858 11.4692 10.4404 11.0995Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49012 11.5425C8.52398 11.2857 8.6284 11.0684 8.87675 10.9555C9.08277 10.8624 9.28596 10.8934 9.48351 10.9894C9.91247 11.2038 10.1806 11.7259 10.11 12.2085C10.0366 12.6968 9.59357 12.9395 9.1505 12.7222C8.74576 12.5237 8.55122 12.1851 8.4931 11.7645C8.48362 11.6959 8.48053 11.626 8.48825 11.5572C8.4888 11.5523 8.48941 11.5474 8.49012 11.5425Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 12.5755L18.679 12.5755L18.679 14.6465L20.75 14.6465L20.75 12.5755ZM22 11.6941C22 11.4905 21.835 11.3255 21.6314 11.3255L17.7976 11.3255C17.594 11.3255 17.429 11.4905 17.429 11.6941L17.429 15.5279C17.429 15.7315 17.594 15.8965 17.7976 15.8965L21.6314 15.8965C21.835 15.8965 22 15.7315 22 15.5279L22 11.6941Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3208 12.5755L3.2498 12.5755L3.2498 14.6465L5.3208 14.6465L5.3208 12.5755ZM6.5708 11.6941C6.5708 11.4905 6.40578 11.3255 6.20222 11.3255L2.36839 11.3255C2.16482 11.3255 1.9998 11.4905 1.9998 11.6941L1.9998 15.5279C1.9998 15.7315 2.16482 15.8965 2.36839 15.8965L6.20222 15.8965C6.40578 15.8965 6.5708 15.7315 6.5708 15.5279L6.5708 11.6941Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8872 18.9997L14.8162 18.9997L14.8162 21.0707L16.8872 21.0707L16.8872 18.9997ZM18.1372 18.1183C18.1372 17.9147 17.9722 17.7497 17.7686 17.7497L13.9348 17.7497C13.7312 17.7497 13.5662 17.9147 13.5662 18.1183L13.5662 21.9521C13.5662 22.1557 13.7312 22.3207 13.9348 22.3207L17.7686 22.3207C17.9722 22.3207 18.1372 22.1557 18.1372 21.9521L18.1372 18.1183Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.13054 4.40735H5.2394C4.5522 4.40735 3.99512 4.96443 3.99512 5.65163V11.3256H5.24512L5.24512 5.65735L8.13054 5.65735V4.40735ZM12.7015 5.65735L18.7503 5.65735V11.3256H20.0003V5.65163C20.0003 4.96443 19.4432 4.40735 18.756 4.40735H12.7015V5.65735ZM3.99512 15.8966V19.1702C3.99512 19.8574 4.5522 20.4145 5.2394 20.4145H13.5663V19.1645L5.24512 19.1645L5.24512 15.8966H3.99512ZM18.756 20.4145H18.1373V19.1645H18.7503V15.8966H20.0003V19.1702C20.0003 19.8574 19.4432 20.4145 18.756 20.4145Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4517 3.75L9.38066 3.75L9.38066 5.821L11.4517 5.821L11.4517 3.75ZM12.7017 2.86858C12.7017 2.66502 12.5366 2.5 12.3331 2.5L8.49924 2.5C8.29568 2.5 8.13066 2.66502 8.13066 2.86858L8.13066 6.70241C8.13066 6.90598 8.29568 7.071 8.49924 7.071L12.3331 7.071C12.5366 7.071 12.7017 6.90598 12.7017 6.70241L12.7017 2.86858Z"
        fill={fill}
      />
    </svg>
  </span>
);

export default GamesVEGconnectIcon;
