import React from "react";

const GamesBanterIcon = ({ width = "20", height = "21", fill = "white" }) => (
  <span>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1503_960" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1306 9.56528C18.1306 14.2958 14.2958 18.1306 9.56528 18.1306C8.49633 18.1306 7.47312 17.9347 6.52956 17.577L3.24709 20L3.32415 15.4315C1.88296 13.8988 1 11.8352 1 9.56528C1 4.83481 4.83481 1 9.56528 1C14.2958 1 18.1306 4.83481 18.1306 9.56528Z"
        />
      </mask>
      <path
        d="M6.52956 17.577L6.97267 16.4082C6.57547 16.2576 6.12897 16.3191 5.7872 16.5713L6.52956 17.577ZM3.24709 20L1.99727 19.9789C1.98925 20.4544 2.25172 20.8932 2.67442 21.1111C3.09713 21.329 3.60684 21.2881 3.98944 21.0057L3.24709 20ZM3.32415 15.4315L4.57397 15.4526C4.57946 15.1271 4.45778 14.8124 4.2348 14.5752L3.32415 15.4315ZM9.56528 19.3806C14.9861 19.3806 19.3806 14.9861 19.3806 9.56528H16.8806C16.8806 13.6054 13.6054 16.8806 9.56528 16.8806V19.3806ZM6.08644 18.7458C7.16939 19.1564 8.34261 19.3806 9.56528 19.3806V16.8806C8.65006 16.8806 7.77685 16.7131 6.97267 16.4082L6.08644 18.7458ZM3.98944 21.0057L7.27191 18.5827L5.7872 16.5713L2.50473 18.9943L3.98944 21.0057ZM2.07433 15.4104L1.99727 19.9789L4.49691 20.0211L4.57397 15.4526L2.07433 15.4104ZM-0.25 9.56528C-0.25 12.1658 0.763 14.5325 2.4135 16.2878L4.2348 14.5752C3.00292 13.2651 2.25 11.5045 2.25 9.56528H-0.25ZM9.56528 -0.25C4.14445 -0.25 -0.25 4.14445 -0.25 9.56528H2.25C2.25 5.52516 5.52516 2.25 9.56528 2.25V-0.25ZM19.3806 9.56528C19.3806 4.14445 14.9861 -0.25 9.56528 -0.25V2.25C13.6054 2.25 16.8806 5.52516 16.8806 9.56528H19.3806Z"
        fill={fill}
        mask="url(#path-1-inside-1_1503_960)"
      />
      <path
        d="M13.5813 7.98052H11.0855V5.48666H7.95967V7.98052H5.46391V11.1083H7.95967V13.604H11.0855V11.1083H13.5813V7.98052Z"
        stroke={fill}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default GamesBanterIcon;
